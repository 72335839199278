import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static get targets() {
    return [ 'organization_address_country' ];
  }

  static get values() {
    return { url: String, param: String };
  }

  change(event) {
    get(`${event.target.dataset.route}?country_code=${event.target.selectedOptions[0].value}`, {
      responseKind: 'turbo-stream'
    });
  }
}
